import { ApiAction } from '../api-actions';
import { ApiResourceModule } from '../api-modules/api-resource-modules';
import { linkModuleAndAction } from '../link-module-and-action';

export const UserRolesPermissions = {
	CREATE_USER_ROLES_PERMISSION: linkModuleAndAction(ApiResourceModule.GROUPS, ApiAction.CREATE),
	DELETE_USER_ROLES_PERMISSION: linkModuleAndAction(ApiResourceModule.GROUPS, ApiAction.DELETE),
	READ_USER_ROLE_PERMISSIONS_PERMISSION: linkModuleAndAction(ApiResourceModule.PERMISSIONS, ApiAction.READ),
	READ_USER_ROLES_PERMISSION: linkModuleAndAction(ApiResourceModule.GROUPS, ApiAction.READ),
	UPDATE_USER_ROLES_PERMISSION: linkModuleAndAction(ApiResourceModule.GROUPS, ApiAction.UPDATE),
};
